.container {
    height: 100vh;
    padding: 5rem;
    box-sizing: border-box;
}

.vector-graphics{
    filter: drop-shadow(0 40px 40px rgba(0,0,0,0.7));
    animation: vectorAnimation 1s infinite ease-in-out alternate;
}

@keyframes vectorAnimation {
    to{
        transform: translateY(-10px);
    }
}

.brandsBanner > svg{
    color: white;
    font-size: 2rem;
    transition: color 0.5s;
}

.brandsBanner > svg:hover{
    color: rgb(225,221,0);
}

.container2{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container2 video{
    border: 1px solid rgba(0,0,0,0.18);
    width: 60%;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.75);
}

.card{
    transition: all 0.5s;
    filter: drop-shadow(0 40px 40px rgba(0,0,0, 0.2));
}

.card:hover{
    transform: translateY(-10px);
}

.link{
    color: rgb(202, 202, 62);
}

@media screen and (max-width: 600px) {
    .container2{
        height: unset;
        padding: 4vh 0;
    }
    .container2 video{
        width: 100%;
    }
}


/* Course Page CSS */

.course{
    width: 250px;
    margin-bottom: 1rem;
    transition: all 0.5s;
}

.course:hover{
    transform: translateY(-10px);
}

/* Ratings */

.rating-outer {
    display: inline-block;
    position: relative;
    font-family: FontAwesome;
    color: #fdcc0d;
}
  
.rating-outer::before {
    content: '\f006 \f006 \f006 \f006 \f006';
}
  
.rating-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
}
  
.rating-inner::before {
    content: '\f005 \f005 \f005 \f005 \f005';
    color: #f8ce0b;
}
  
  